/*this ie for edge*/
::-ms-reveal {
    display: none;
  }

body.login {
    overflow-y: auto;
    .login-modal{
        .login-box{
            .cookie-link{
                list-style: none;
                margin: 10px 0 20px;
                padding: 0;
                text-align: left;
                li{
                    display: inline-block;
                    margin-right: 30px;
                }
            }
        }
        .login-form .form-group input.form-control{
            &.no-icon{
                padding-left: 10px !important;
            }
        }
    }

    .toggle-password{
        margin-top: 5px;;
        input[type='checkbox']{
            display: none;
            cursor: pointer;
            & + label{
                display: inline-block;
                @height: 18px;
                height: @height;
                width: @height;
                line-height: @height - 4px;
                background: #fff;
                border: 2px solid @input-border;
            }
            &:checked + label{
                background: url('@{base-img-url}/tick.png') no-repeat center #fff;
            }
        }
        label{
            font-size: .9rem;
            vertical-align: middle;
            cursor: pointer;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    .form-group{
        position: relative;
        .field-icon {
            position: absolute;
            top: 7px;
            right: 10px;
            z-index: 2;
            &:hover {
                cursor: pointer;
            }
        }
    }
 
}